<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0">
                    <div class="col pl-0" data-input-subtitle="Data incial" :class="{'mt-3 pr-0': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="col pl-0" data-input-subtitle="Data final" :class="{'pr-0': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="col pl-0 pr-0">
                        <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div class="card" v-if="financial.list.length">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12">Relatório cassino</div>
                            </div><!-- /row -->
                        </div><!-- /card-header -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ financial.total_apostas | formatValue }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-dice-three"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Apostas</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ format_coin(financial.total_apurado) }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-piggy-bank"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Apuração</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ format_coin(financial.total_premios) }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-award"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Premiações</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                                <div class="w-100 mt-3"></div>
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ format_coin(financial.total_saldo) }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-dollar-sign"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Saldo</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ format_coin(financial.total_comissao) }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-percent"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Comissão ({{ financial.comissao }}% - GGR)</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                <div class="col">
                                    <div class="card bg-dark text-white p-3">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">
                                                    <span>{{ format_coin(financial.total_liquido) }}</span>
                                                </h5>
                                            </div><!-- /col -->
                                            <div class="col-auto">
                                                <i class="fas fa-hand-holding-usd"></i>
                                            </div><!-- /col-3 -->
                                        </div><!-- /row -->
                                        <h6 class="text-warning font-weight-normal">Saldo líquido</h6>
                                    </div><!-- /card -->
                                </div><!-- /col -->
                            </div><!-- /row -->
                            <div class="table-responsive">
                                <table class="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Jogo</th>
                                            <th scope="col">Rodadas</th>
                                            <th scope="col">Apurado</th>
                                            <th scope="col">Premiações</th>
                                            <th scope="col">Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in financial.list" :key="index">
                                            <th scope="row">{{ item.gameId }}</th>
                                            <td>{{ item.gameName }}</td>
                                            <td>{{ item.rodadas | formatValue }}</td>
                                            <td>R$ {{ format_coin(item.total_apostas) }}</td>
                                            <td>R$ {{ format_coin(item.total_premios) }}</td>
                                            <td :class="item.total_saldo < 0 ? 'text-danger' : 'text-success'">R$ {{ format_coin(item.total_saldo) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div><!-- /table-responsive-->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            financial: {
                list: [],
                comissao: 0,
                total_saldo: 0,
                total_apurado: 0,
                total_premios: 0,
                total_apostas: 0,
                total_liquido: 0,
                total_comissao: 0
            },
            date1: today,
            date2: today,
            isLoading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        search() {

            const self = this;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {

                self.isLoading = true;

                api.get(`casino/financial/${self.date1}/between/${self.date2}`).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                            self.financial = response.data.financial;
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        }
    },
    filters: {
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.table-responsive thead tr th {
    border-top: 0!important;
}
.table-responsive td {
    white-space: nowrap;
}
</style>